import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../../data/DataAccess";
import { Logging } from "../../../Logging";

import "../../../assets/css/AOS2/AOS2.css";
import "../../../assets/css/AOS2/AOS2_ColourSchemes.css";
import "../../../assets/css/AOS2/AOS2_ChoicesOffers.css";

import AOS_MNAE from "../MNAE/MNAE";
import AOS2_Linked_Student_Withdrawals from "../AOS2_Linked_Student_Withdrawals";
import { NonAgentUser } from "./NonAgentUser";
import { InactiveAgent } from "./InactiveAgent";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { AgentNotices } from "../Components/AgentNotices";
import { AgentSidebar } from "../Components/AgentSidebar";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
  Navigate: NavigateFunction;
}

interface IState {
  // functional state parts
  Errors: string[];

  // PAS Panel
  _PASUCASExpand: boolean;
  _PASUCASName: string;
  _PASUCASNumber: string;
  _PASUCASSuccess: boolean;

  // Search Panel
  _SearchExpand: boolean;
  _SearchFor: string;

  // data-ret state parts
  AgentName: string;
  AgentEmail: string;
  AgentReference: string;
  AgentContactName: string;
  AgentAddress: string;
  AgentCountry: string;
  AgentIsPAS: boolean;
  AgentActive: boolean;
  PGNewAppLink: string;
  PGNewAppLinkExpiry: number;
  PGNewPanelNotices: { Title: string; Body: string }[];
  UGNewAppLink: string;
  UGNewAppLinkExpiry: number;
  UGNewPanelNotices: { Title: string; Body: string }[];
}

declare global {
  interface Window {
    showDeclareUCAS?: any;
  }
}

function AOS2(params): React.ReactElement {
  let navigate = useNavigate();
  return <AOS2_INNER {...params} Navigate={navigate} />;
}

class AOS2_INNER extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      _PASUCASExpand: false,
      _PASUCASName: "",
      _PASUCASNumber: null,
      _PASUCASSuccess: false,

      _SearchExpand: false,
      _SearchFor: "",
      Errors: [],

      AgentName: "",
      AgentEmail: "",
      AgentReference: "",
      AgentContactName: "",
      AgentAddress: "",
      AgentCountry: "",
      AgentActive: false,
      AgentIsPAS: false,
      PGNewAppLink: "",
      PGNewAppLinkExpiry: null,
      PGNewPanelNotices: [],
      UGNewAppLink: "",
      UGNewAppLinkExpiry: null,
      UGNewPanelNotices: [],
    };
  }

  public async UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<IProps>,
    nextContext: any,
  ): Promise<void> {
    if (nextProps.Alias != this.props.Alias) {
      await this.RetrievePanelDetails(nextProps.Alias);
      await this.RetrievePGNewApplicationPanelData(nextProps.Alias);
      await this.RetrieveUGNewApplicationPanelData(nextProps.Alias);
    }
  }

  public async componentDidMount() {
    window.document.title = "University of Essex - Agent Online Services";
    await this.RetrievePanelDetails(this.props.Alias);
    await this.RetrievePGNewApplicationPanelData(this.props.Alias);
    await this.RetrieveUGNewApplicationPanelData(this.props.Alias);
  }

  render() {
    const RORelay = {
      da: this.props.da,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    if (this.state.AgentName === "") {
      if (this.props.Mode[0] === "Utilities") {
        if (this.props.Mode[1] === "NoticeEmbargoManager") {
          return <AOS_MNAE {...RORelay}></AOS_MNAE>;
          //  } else if (this.props.Mode[1] === 'EmbargoManager') {
          //    return <AOS2_Manage_Embargoes {...RORelay}></AOS2_Manage_Embargoes>
        } else if (this.props.Mode[1] === "LinkedWithdrawals") {
          return (
            <AOS2_Linked_Student_Withdrawals
              {...this.props}
            ></AOS2_Linked_Student_Withdrawals>
          );
        }
      }
      return (
        <NonAgentUser
          MODE_DEVELOPMENT={this.props.MODE_DEVELOPMENT}
          MODE_STAGING={this.props.MODE_STAGING}
        />
      );
    } else if (this.state.AgentActive === false) {
      return <InactiveAgent />;
    }

    return (
      <>
        <AgentSidebar />

        <div className="AOSPanels">
          <AgentNotices
            Embargo={this.props.Embargo}
            HasNotices={this.props.HasNotices}
            Notices={this.props.Notices}
          />
          <div className="AOSPanelCluster">
            {this.state.UGNewAppLink !== undefined &&
            this.state.UGNewAppLink !== "" ? (
              //&& this.state.UGNewAppLinkExpiry > Date.now()
              <Link
                className="AOSPanel"
                to={this.state.UGNewAppLink}
                target="_blank"
              >
                <div className="PanelBar Title UG">
                  <div className="Left">Begin New</div>
                  <div className="Breaker"></div>
                  <div className="Right">Undergraduate</div>
                </div>
                <div className="PanelBody">
                  Begin a new Undergraduate application.
                  <br />
                  (link opens in new window)
                </div>
              </Link>
            ) : (
              <div className="AOSPanel GreyOut">
                <div className="PanelBar Title UG">
                  <div className="Left">Begin New</div>
                  <div className="Breaker"></div>
                  <div className="Right">Undergraduate</div>
                </div>
                <div className="PanelBody">
                  Acquiring access token...
                  <br />
                  If this message doesn't go away, press F5 to refresh.
                </div>
              </div>
            )}
            {this.props.Embargo("UCAS") ? (
              <div className="AOSPanel Embargoed">
                <div className="PanelBar Title UG">
                  <div className="Left">Current</div>
                  <div className="Breaker"></div>
                  <div className="Right">Undergraduate</div>
                </div>
                <div className="PanelBody">
                  {this.props.Notices(this.state.AgentIsPAS, ["CurrentApps"])}
                </div>
              </div>
            ) : (
              <Link
                className="AOSPanel"
                to={this.props.GetLink(
                  [
                    { Mode: "ug", Index: 0 },
                    { Mode: "current", Index: 1 },
                  ],
                  [],
                )}
              >
                <div className="PanelBar Title UG">
                  <div className="Left">Current</div>
                  <div className="Breaker"></div>
                  <div className="Right">Undergraduate</div>
                </div>
                <div className="PanelBody">View your current applications.</div>
              </Link>
            )}
            <Link
              className="AOSPanel"
              to={this.props.GetLink(
                [
                  { Mode: "ug", Index: 0 },
                  { Mode: "unsubmitted", Index: 1 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title UG">
                <div className="Left">Unsubmitted</div>
                <div className="Breaker"></div>
                <div className="Right">Undergraduate</div>
              </div>
              <div className="PanelBody">
                View your unsubmitted applications.
              </div>
            </Link>
            <Link
              className="AOSPanel"
              to={this.props.GetLink(
                [
                  { Mode: "ug", Index: 0 },
                  { Mode: "archive", Index: 1 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title UG">
                <div className="Left">Archive</div>
                <div className="Breaker"></div>
                <div className="Right">Undergraduate</div>
              </div>
              <div className="PanelBody">View your historic applications.</div>
            </Link>
          </div>
          <div className="AOSPanelCluster">
            {this.state.PGNewAppLink !== undefined &&
            this.state.PGNewAppLink !== "" ? (
              //&& this.state.PGNewAppLinkExpiry > Date.now()
              <Link
                className="AOSPanel"
                to={this.state.PGNewAppLink}
                target="_blank"
              >
                <div className="PanelBar Title PG">
                  <div className="Left">Begin New</div>
                  <div className="Breaker"></div>
                  <div className="Right">Postgraduate</div>
                </div>
                <div className="PanelBody">
                  Begin a new Postgraduate application.
                  <br />
                  (link opens in new window)
                </div>
              </Link>
            ) : (
              <div className="AOSPanel GreyOut">
                <div className="PanelBar Title PG">
                  <div className="Left">Begin New</div>
                  <div className="Breaker"></div>
                  <div className="Right">Postgraduate</div>
                </div>
                <div className="PanelBody">
                  Acquiring access token...
                  <br />
                  If this message doesn't go away, press F5 to refresh.
                </div>
              </div>
            )}
            <Link
              className="AOSPanel"
              to={this.props.GetLink(
                [
                  { Mode: "pg", Index: 0 },
                  { Mode: "current", Index: 1 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title PG">
                <div className="Left">Current</div>
                <div className="Breaker"></div>
                <div className="Right">Postgraduate</div>
              </div>
              <div className="PanelBody">View your current applications.</div>
            </Link>
            <Link
              className="AOSPanel"
              to={this.props.GetLink(
                [
                  { Mode: "pg", Index: 0 },
                  { Mode: "unsubmitted", Index: 1 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title PG">
                <div className="Left">Unsubmitted</div>
                <div className="Breaker"></div>
                <div className="Right">Postgraduate</div>
              </div>
              <div className="PanelBody">
                View your unsubmitted applications.
              </div>
            </Link>
            <Link
              className="AOSPanel"
              to={this.props.GetLink(
                [
                  { Mode: "pg", Index: 0 },
                  { Mode: "archive", Index: 1 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title PG">
                <div className="Left">Archive</div>
                <div className="Breaker"></div>
                <div className="Right">Postgraduate</div>
              </div>
              <div className="PanelBody">View your historic applications.</div>
            </Link>
          </div>
          <div className="AOSPanelCluster Row">
            <div
              className={
                "AOSPanel Search" + (this.state._SearchExpand ? " Active" : "")
              }
            >
              <div
                className="PanelBar Title Search"
                onClick={() => {
                  this.setState({ _SearchExpand: !this.state._SearchExpand });
                }}
              >
                <div className="Left">Search</div>
                <div className="Breaker"></div>
                <div className="Right">All Levels</div>
              </div>
              {/* <div
                className="PanelBar Action Search"
                onClick={() => {
                  this.setState({ _SearchExpand: !this.state._SearchExpand });
                }}
              >
                &gt; Search
              </div> */}
              {this.state._SearchExpand ? (
                <div className="PanelBody">
                  <div id="DeclareUCASApplicants">
                    <p className="InputRow">
                      <label htmlFor="Searcher">
                        Enter PG number, DA number, UCAS number, or Surname:
                      </label>
                      <input
                        type="text"
                        value={this.state._SearchFor}
                        id="Searcher"
                        name="Searcher"
                        onChange={(event) => {
                          const pattern = /^[0-9A-Za-z '\-]*$/g;
                          const inp = event.target.value;
                          this.setState({
                            _SearchFor:
                              inp === ""
                                ? ""
                                : pattern.test(inp)
                                  ? inp
                                  : this.state._SearchFor,
                          });
                        }}
                        onKeyUp={(ev) => {
                          if (ev.key === "Enter") {
                            this.props.Navigate(
                              "/search/" +
                                this.state._SearchFor
                                  .trim()
                                  .replaceAll(" ", "_"),
                            );
                          }
                        }}
                      />
                    </p>
                    <p className="SubmitRow">
                      <Link
                        to={
                          "/search/" +
                          this.state._SearchFor.trim().replaceAll(" ", "_")
                        }
                      >
                        Search
                      </Link>
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className="PanelBody"
                  onClick={() => {
                    this.setState({ _SearchExpand: !this.state._SearchExpand });
                  }}
                >
                  <div id="DeclareUCASApplicants">Search all applications.</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  private RetrievePanelDetails(alias: string): Promise<any> {
    return this.props.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.AOSv2_GetPanelDetails",
          params: ["@username", "{%iu}"],
          imp: alias,
        }),
      )
      .then(
        (data) => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else if (data !== undefined) {
            if (data.length === 1) {
              if (data[0] !== undefined) {
                if (data[0].length === 1) {
                  this.setState({
                    AgentName: data[0][0].AgentName,
                    AgentEmail: data[0][0].AgentEmail,
                    AgentReference: data[0][0].AgentReference,
                    AgentContactName: data[0][0].AgentContactName,
                    AgentAddress: data[0][0].AgentAddress,
                    AgentCountry: data[0][0].AgentCountry,
                    AgentIsPAS: data[0][0].AgentIsPAS === "True",
                    AgentActive: data[0][0].AgentActive === "True",
                  });
                } else {
                  _error =
                    "RetrievePanelDetails - Data returned an unexpected number of rows.";
                }
              } else {
                _error =
                  "RetrievePanelDetails - Data returned a null singleton.";
              }
            } else {
              _error =
                "RetrievePanelDetails - Data returned an unexpected number of tables.";
            }
          } else {
            _error = "RetrievePanelDetails - Data returned undefined.";
          }

          if (_error !== "") {
            this.setState({ Errors: [...this.state.Errors].concat([_error]) });
          }
        },
        (reason) => {
          this.setState({ Errors: [...this.state.Errors].concat([reason]) });
        },
      );
  }

  private RetrievePGNewApplicationPanelData(alias: string): Promise<any> {
    return this.props.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.AOSv2_GetPGNewApplicationPanel",
          params: ["@username", "{%iu}"],
          imp: alias,
        }),
      )
      .then(
        (data) => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else if (data !== undefined) {
            if (data.length > 0) {
              if (data[0] !== undefined) {
                if (data[0].length === 1) {
                  this.setState({
                    PGNewAppLink: data[0][0].LINK,
                    PGNewAppLinkExpiry: Date.parse(data[0][0].EXPIRY),
                  });
                } else {
                  _error =
                    "RetrievePGNewApplicationPanelData - Data returned an unexpected number of rows.";
                }
              } else {
                _error =
                  "RetrievePGNewApplicationPanelData - Data returned a null singleton.";
              }
            }
            if (data.length > 1) {
              // * from webui.Notices
              if (data[0] !== undefined) {
                var _Notices: { Title: string; Body: string }[] = [];
                data[0].forEach((th) => {
                  _Notices = _Notices.concat([
                    { Title: th.title, Body: th.Body_Plain },
                  ]);
                });
                this.setState({ PGNewPanelNotices: _Notices });
              } else {
                _error =
                  "RetrievePGNewApplicationPanelData - Data returned a null singleton.";
              }
            }
            if (data.length <= 0) {
              _error =
                "RetrievePGNewApplicationPanelData - Data returned an unexpected number of tables.";
            }
          } else {
            _error =
              "RetrievePGNewApplicationPanelData - Data returned undefined.";
          }

          if (_error !== "") {
            this.setState({ Errors: [...this.state.Errors].concat([_error]) });
          }
        },
        (reason) => {
          this.setState({ Errors: [...this.state.Errors].concat([reason]) });
        },
      );
  }

  private RetrieveUGNewApplicationPanelData(alias: string): Promise<any> {
    return this.props.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.AOSv2_GetUGNewApplicationPanel",
          params: ["@username", "{%iu}"],
          imp: alias,
        }),
      )
      .then(
        (data) => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else if (data !== undefined) {
            if (data.length > 0) {
              if (data[0] !== undefined) {
                if (data[0].length === 1) {
                  this.setState({
                    UGNewAppLink: data[0][0].LINK,
                    UGNewAppLinkExpiry: Date.parse(data[0][0].EXPIRY),
                  });
                } else {
                  _error =
                    "RetrieveUGNewApplicationPanelData - Data returned an unexpected number of rows.";
                }
              } else {
                _error =
                  "RetrieveUGNewApplicationPanelData - Data returned a null singleton.";
              }
            }
            if (data.length > 1) {
              // * from webui.Notices
              if (data[0] !== undefined) {
                var _Notices: { Title: string; Body: string }[] = [];
                data[0].forEach((th) => {
                  _Notices = _Notices.concat([
                    { Title: th.title, Body: th.Body_Plain },
                  ]);
                });
                this.setState({ PGNewPanelNotices: _Notices });
              } else {
                _error =
                  "RetrieveUGNewApplicationPanelData - Data returned a null singleton.";
              }
            }
            if (data.length <= 0) {
              _error =
                "RetrieveUGNewApplicationPanelData - Data returned an unexpected number of tables.";
            }
          } else {
            _error =
              "RetrieveUGNewApplicationPanelData - Data returned undefined.";
          }

          if (_error !== "") {
            this.setState({ Errors: [...this.state.Errors].concat([_error]) });
          }
        },
        (reason) => {
          this.setState({ Errors: [...this.state.Errors].concat([reason]) });
        },
      );
  }
}

export default AOS2;
