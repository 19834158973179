import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../data/DataAccess";
import { Logging } from "../../Logging";
import "../../assets/css/Clearing_LF/Clearing.css";
import IfPermitted from "../IfPermitted";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<string>;
  AuthedUser: User;
  ViewAsUser: User;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;

  Navigation: (url: string) => void;
}

interface IState {
  // functional state parts
  Errors: string[];
  _SearchFor: string;
}

class Clearing_LF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      _SearchFor: "",
      Errors: [],
    };
  }

  public async componentDidMount() {
    window.document.title = "University of Essex - Clearing Documents";
  }

  render() {
    return (
      <React.Fragment>
        <div className="StructPanels">
          <div className="StructPanelCluster Row Halved">
            <div className="StructPanel Cassette">
              <IfPermitted Whitelist={["Clearing.Reader"]}>
                <div className="PanelBar Title Search">
                  <div className="Left">Search Applicant Records</div>
                  <div className="Breaker"></div>
                  <div className="Right">Clearing</div>
                </div>

                <div className="PanelBody">
                  <div id="DeclareUCASApplicants">
                    <p className="InputRow">
                      Use this search to view applicant records which require
                      files to have the Applicant No verified/reviewed in order
                      for the files to move onto the applicant’s UG EAF record.
                      If you do not see the applicant you are looking for in the
                      results shown, you may need to adjust your search term
                      (i.e. try using 'smi' instead of 'smith').
                    </p>
                    <p className="InputRow">
                      Click on 'Review' link at the end of the applicant's row
                      to proceed.
                    </p>
                    <br />
                    <p className="InputRow">
                      <label htmlFor="Searcher">
                        Enter a Clearing Ref No, Surname or Applicant Number:
                      </label>
                    </p>
                    <p className="InputRow">
                      <input
                        type="text"
                        value={this.state._SearchFor}
                        id="Searcher"
                        name="Searcher"
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            this.props.Navigation(
                              "/Clearing/Search/" +
                                this.state._SearchFor
                                  .trim()
                                  .replaceAll(" ", "_"),
                            );
                          }
                        }}
                        onChange={(event) => {
                          const pattern = /^[0-9A-Za-z '\-]*$/g;
                          const inp = event.target.value;
                          this.setState({
                            _SearchFor:
                              inp === ""
                                ? ""
                                : pattern.test(inp)
                                  ? inp
                                  : this.state._SearchFor,
                          });
                        }}
                      />
                    </p>
                    <p className="SubmitRow Left">
                      <a
                        href={this.props.GetLink(
                          [
                            { Mode: "Clearing", Index: 0 },
                            { Mode: "Search", Index: 1 },
                            {
                              Mode: this.state._SearchFor
                                .trim()
                                .replaceAll(" ", "_"),
                              Index: 2,
                            },
                          ],
                          [],
                        )}
                      >
                        Search
                      </a>
                    </p>
                  </div>
                </div>
              </IfPermitted>
            </div>
            <IfPermitted Whitelist={["Clearing.Crew"]}>
              {/* <div className='StructPanelCluster Row Halved'> */}
              <a
                className="StructPanel"
                href={this.props.GetLink(
                  [
                    { Mode: "Clearing", Index: 0 },
                    { Mode: "Upload", Index: 1 },
                  ],
                  [],
                )}
              >
                <div className="PanelBar Title">
                  <div className="Left">Upload Documents</div>
                  <div className="Breaker"></div>
                  <div className="Right">Clearing</div>
                </div>
                {/* <div className='PanelBar Action UG'>Upload Clearing Documents</div> */}
                <div className="PanelBody">
                  Use this tool to upload or modify received Clearing documents.
                </div>
              </a>
              {/* </div> */}
            </IfPermitted>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Clearing_LF;
