import { PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import { DataAccess, User } from "../../../data/DataAccess";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../../Logging";
import "../../../assets/css/AOS2/AOS2_Search.css";
import { AgentSidebar } from "../Components/AgentSidebar";
import {
  Link,
  NavigateFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  Search: string;

  AgentName: string;
  AgentEmail: string;
  AgentReference: string;
  AgentContactName: string;
  AgentAddress: string;
  AgentCountry: string;
  AgentIsPAS: boolean;
  Navigate: NavigateFunction;
}

interface IState {
  _search: string;
}

function AOS2_SEARCH(params): React.ReactElement {
  let search: any = useLoaderData();
  let navigate = useNavigate();
  return (
    <AOS2_SEARCH_INNER
      {...params}
      Search={search.SearchTerm}
      Navigate={navigate}
    />
  );
}

class AOS2_SEARCH_INNER extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      _search: props.Search,
    };
  }

  render() {
    var PassToAllGrids = {
      Callouts: false,
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      prid: this.props.Mode[1],
      class: "UoE-Grid AOS-AppList",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <React.Fragment>
        <AgentSidebar />
        <div className="ResultsPanel Search">
          <h2>Search All Applications</h2>
          <div className="Desc">Enter PG Number, DA Number or Surname.</div>
          <input
            className="SearchText"
            type="text"
            pattern="^[0-9A-Za-z '\-]*$"
            value={this.state._search}
            onChange={(event) => {
              const pattern = /^[0-9A-Za-z '\-]*$/g;
              const inp = event.target.value;
              this.setState({
                _search:
                  inp === ""
                    ? ""
                    : pattern.test(inp)
                      ? inp
                      : this.state._search,
              });
            }}
            onKeyUp={(ev) => {
              if (ev.key === "Enter") {
                this.props.Navigate(
                  "/search/" + this.state._search.trim().replaceAll(" ", "_"),
                );
              }
            }}
          />
          <Link
            id="MainSearchButton"
            className="SearchButton"
            to={"/search/" + this.state._search}
          >
            Search
          </Link>
          {this.props.Search !== undefined && this.props.Search !== "" ? (
            <React.Fragment>
              <div className="SearchKey">
                <div className="UGCurrentApp"></div>
                <div className="KeyLabel">
                  Current Undergraduate Application
                </div>
                <div className="UGPastApp"></div>
                <div className="KeyLabel">
                  Archive Undergraduate Application
                </div>
                <div className="PGCurrentApp"></div>
                <div className="KeyLabel">Current Postgraduate Application</div>
                <div className="PGPastApp"></div>
                <div className="KeyLabel">Archive Postgraduate Application</div>
              </div>
              <GridWrapper
                eid={"AOS_Search_" + this.props.Refresher}
                {...{
                  ...PassToAllGrids,
                  class: PassToAllGrids.class.replace("AOS-AppList", "Search"),
                }}
                sproc="sputilities.webui.AOSv2_AllApplicantSearch"
                params={[
                  "@username",
                  "{%iu}",
                  "@searchterm",
                  this.props.Search,
                ]}
                rename="URL,Name_URL"
              ></GridWrapper>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default AOS2_SEARCH;
